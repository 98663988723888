<template>
  <HeaderComponent />
  <div class="card-product__container center">
    <nav aria-label="Breadcrumb" class="catalog-container__breadcrumb center">
      <ul>
        <li>
          <router-link to="/" class="catalog-container__breadcrumb-activeLink"
            >Главная</router-link
          >
        </li>
        <li>
          <router-link
            to="/catalogInvertCondir"
            class="catalog-container__breadcrumb-activeLink"
            >Инверторные кондиционеры</router-link
          >
        </li>
      </ul>
    </nav>

    <div class="card-product__product-box center">
      <div class="">
        <h1 class="card-product__model-product">
          {{ selectedProductIn.model }}
        </h1>
        <div class="card-product__top-section">
          <swiper
            :cssMode="true"
            :navigation="true"
            :pagination="true"
            :mousewheel="true"
            :keyboard="true"
            :modules="modules"
            class="card-product__mySwiper"
          >
            <swiper-slide
              ><img
                class="card-product__image-product"
                :src="selectedProductIn.image"
                alt="Изображение товара"
            /></swiper-slide>
            <swiper-slide
              ><img
                class="card-product__image-product"
                :src="selectedProductIn.image2"
                alt="Изображение товара"
            /></swiper-slide>
            <swiper-slide
              ><img
                class="card-product__image-product"
                :src="selectedProductIn.image3"
                alt="Изображение товара"
            /></swiper-slide>
            <swiper-slide
              ><img
                class="card-product__image-product"
                :src="selectedProductIn.image4"
                alt="Изображение товара"
            /></swiper-slide>
          </swiper>

          <div class="card-product__box-specifications">
            <div class="card-product__price-box">
              <div class="card-product__price">
                {{ selectedProductIn.price }}
              </div>
              <div class="card-product__priceOld">
                {{ selectedProductIn.priceOld }}
              </div>
            </div>
            <ul v-for="(item, id) in selectedProductIn.description" :key="id">
              <li class="card-product__specifications-item">{{ item }}</li>
            </ul>
          </div>
        </div>
        <div class="card-product__line"></div>
        <div class="card-product__box-description">
          <h2 class="card-product__description-title">
            {{ selectedProductIn.functionTitle }}
          </h2>
          <div class="card-product__description-box-text">
            <p class="card-product__description-text">
              {{ selectedProductIn.functionText1 }}
            </p>
            <p class="card-product__description-text">
              {{ selectedProductIn.functionText2 }}
            </p>
            <p class="card-product__description-text">
              {{ selectedProductIn.functionText3 }}
            </p>
          </div>

          <ul
            class="card-product__description-list"
            v-for="(functionItem, id) in selectedProductIn.functionList"
            :key="id"
          >
            <li class="card-product__description-item">
              {{ functionItem.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <FooterComponent />
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import FooterComponent from "../components/FooterComponent.vue";
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
export default {
  components: {
    HeaderComponent,
    FooterComponent,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      modules: [Navigation, Pagination, Mousewheel, Keyboard],
    };
  },
  computed: {
    ...mapGetters(["productsInvert", "brandsInvert"]),
    selectedProductIn() {
      const productId = this.$route.params.productId;
      return productId && this.productsInvert.length
        ? this.productsInvert.find((product) => product.id === +productId)
        : null;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  width: 50%;
  height: 50%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 80%;
  height: 80%;
  object-fit: cover;
  padding-bottom: 40px;
  padding-top: 30px;
}

@media (max-width: 768px) {
  .swiper {
    width: 80%;
    height: 80%;
  }
}

@media (max-width: 530px) {
  .swiper {
    width: 90%;
    height: 90%;
  }
}

@media (max-width: 425px) {
  .swiper-slide img {
    width: 100%;
  }
}
</style>
