<template>
  <header class="homeBanner">
    <div class="navigation">
      <input type="checkbox" class="navigation__toggle" id="nav-toggle" />
      <label for="nav-toggle" class="navigation__button"
        ><span class="navigation__icon"></span
      ></label>
      <div class="navigation__background"></div>

      <nav class="navigation__nav">
        <ul class="navigation__menu-links">
          <li>
            <router-link to="/" class="navigation__menu-link"
              >ГЛАВНАЯ</router-link
            >
          </li>
          <li>
            <router-link to="/catalogHomeCondir" class="navigation__menu-link"
              >Кондиционеры для Квартиры и Дома
            </router-link>
          </li>
          <li>
            <router-link to="/catalogInvertCondir" class="navigation__menu-link"
              >Инверторные кондиционеры
            </router-link>
          </li>
          <li>
            <router-link to="/services" class="navigation__menu-link"
              >НАШИ УСЛУГИ
            </router-link>
          </li>
          <div class="navigation__contacts">
            <div class="navigation__phone-box">
              <img
                class="navigation__phone-icon"
                src="../assets/image/phone.svg"
                alt="icon-phone"
              />
              <span class="navigation__number-phone">+7 900 080-12-87</span>
            </div>
            <div class="navigation__phone-box">
              <img
                class="navigation__phone-icon"
                src="../assets/image/phone.svg"
                alt="icon-phone"
              />
              <span class="navigation__number-phone">+7 900 080-12-87</span>
            </div>
          </div>
        </ul>
      </nav>
    </div>
    <div class="homeBanner__container">
      <div class="homeBannerr__top-layer">
        <div class="homeBanner__logo">
          <router-link to="/"
            ><img
              class="homeBanner__logo-icon"
              src="../assets/image/gallery/logo1.png"
              alt="Логотип Климат Комплекс"
            />
          </router-link>
        </div>
      </div>
      <nav class="homeBanner__nav">
        <router-link
          to="/catalogHomeCondir"
          class="homeBanner__nav-link homeBanner__linkOne"
          >Кондиционеры для Квартиры и Дома</router-link
        >
        <router-link
          to="/catalogInvertCondir"
          class="homeBanner__nav-link homeBanner__linkTwo"
          >Инверторные кондиционеры</router-link
        >
        <router-link
          to="/services"
          class="homeBanner__nav-link homeBanner__linkThree"
          >НАШИ УСЛУГИ</router-link
        >
      </nav>

      <div class="homeBanner__slogan-box">
        <h1 class="homeBanner__slogan">
          "КЛИМАТ КОМПЛЕКС" - поможем вам подобрать и установить кондиционер в
          Челябинске!
        </h1>
        <div class="homeBanner__phone-box">
          <div class="homeBanner__number-phone">+7 900 077-07-19</div>
          <div class="homeBanner__number-phone">+7 900 080-12-87</div>
        </div>
      </div>
    </div>
  </header>

  <div class="content-box center">
    <div class="content-box__catalog-menu">
      <router-link
        to="/catalogHomeCondir"
        class="content-box__catalog-menu-link"
        >Кондиционеры для Квартиры и Дома
        <span class="content-box__flare"></span
      ></router-link>
      <router-link
        to="/catalogInvertCondir"
        class="content-box__catalog-menu-link"
        >Инверторные кондиционеры <span class="content-box__flare"></span
      ></router-link>
    </div>
    <div class="content-box__article">
      <h1 class="content-box__title-article">
        Как выбрать кондиционер для дома
      </h1>
      <div class="content-box__text-article">
        <p class="content-box__text-item">
          Чем отличаются разные виды кондиционеров? Как не&nbsp;ошибиться
          в&nbsp;выборе? В&nbsp;какую сумму можно уложиться при покупке? Какими
          характеристиками должен обладать подходящий кондиционер? Эти
          и&nbsp;многие другие вопросы возникают, когда запланировалось
          приобретение сплит-системы. Давайте разбираться!
        </p>
        <p class="content-box__text-item">
          <img
            class="content-box__image content-box__image_one"
            src="../assets/image/gallery/conder.webp"
            width="500"
            alt="Иллюстрация"
            align="left"
            vspace="5"
            hspace="5"
          />
          <span class="content-box__span">Площадь помещения.</span> Существует
          неформальное правило, согласно которому на&nbsp;один квадратный метр
          площади помещения должно приходиться 100&nbsp;Вт мощности
          кондиционера. Допустим, кондиционер с&nbsp;холодопроизводительностью
          2-2,5 кВт справится с&nbsp;поддержанием комфортной температуры
          в&nbsp;комнате площадью до&nbsp;20&nbsp;квадратных метров метров, при
          условии, что там не&nbsp;находятся дополнительные источники тепла.
          Нередко производитель завышает этот параметр, поэтому приходится
          покупать &laquo;с&nbsp;запасом&raquo; по&nbsp;мощности. Также нужно
          учесть высоту потолков, потому что на&nbsp;самом деле кондиционер
          охлаждает не&nbsp;площадь, а&nbsp;объём, поэтому если ваши потолки
          существенно выше, чем 2,5-3&nbsp;метра, формулу следует
          подкорректировать.
        </p>
        <p class="content-box__text-item">
          <span class="content-box__span">Уровень шума кондиционера</span> —
          довольно важный параметр. Особенно если предполагается установка
          кондиционера в&nbsp;жилом помещении или в&nbsp;спальне. Максимальный
          уровень шума кондиционера, как и&nbsp;мощность, указывается
          в&nbsp;инструкции.
        </p>
        <p class="content-box__text-item">
          <span class="content-box__span">Уровень расхода электроэнергии.</span>
          Как и&nbsp;у&nbsp;многих других бытовых приборов, у&nbsp;кондиционеров
          имеются классы энергоэффективности. Самым энергоэффективным
          на&nbsp;сегодняшний день является класс&nbsp;А+++, который, впрочем,
          встречается не&nbsp;слишком часто, а&nbsp;сами приборы с&nbsp;таким
          классом энергоэффективности будут стоить существенно дороже своих
          менее экономных собратьев. Нужно сказать, что максимальное потребление
          кондиционера на&nbsp;20&nbsp;кв м&nbsp;&mdash; 600 ватт в&nbsp;час,
          а&nbsp;у&nbsp;инверторного&nbsp;&mdash; 300-400&nbsp;ватт, что
          соответствует потреблению обычного компьютера с&nbsp;монитором. Таким
          образом, если кондиционер потребляет 600&nbsp;Вт в&nbsp;час,
          то&nbsp;за&nbsp;ночь работы он&nbsp;потребит 6кВт, в&nbsp;месяц
          (30&nbsp;дней)&nbsp;&mdash; 180&nbsp;кВт.
        </p>
        <p class="content-box__text-item">
          <img
            class="content-box__image content-box__image_two"
            src="../assets/image/gallery/conder2.webp"
            width="500"
            alt="Иллюстрация"
            align="right"
            vspace="5"
            hspace="5"
          />
          <span class="content-box__span"> Управление.</span> Самый простой
          способ управления кондиционером&nbsp;&mdash; механическая панель,
          допускающая включение одного из&nbsp;режимов. Например,
          &laquo;вентилятор + холод сильно/вентилятор + холод слабо/вентилятор
          сильно/вентилятор слабо&raquo;. Понятно, что с&nbsp;помощью такой
          панели управления настроить наиболее комфортную температуру
          в&nbsp;помещении будет не&nbsp;слишком просто.
          А&nbsp;следовательно&nbsp;&mdash; стоит серьезно подумать, прежде чем
          покупать кондиционер с&nbsp;таким примитивным управлением. Подавляющее
          большинство моделей допускают более точную настройку, включающую
          точную установку температуры, таймер, несколько режимов мощности
          и&nbsp;т.п. Практически все &laquo;серьезные&raquo; модели
          укомплектовываются пультом дистанционного управления. На&nbsp;наш
          взгляд, в&nbsp;данном случае экономить не&nbsp;стоит: лучше приобрести
          прибор, позволяющий максимально точно настроить нужный режим, чем
          экспериментировать с&nbsp;кондиционерами, способными работать лишь
          в&nbsp;нескольких режимах.
        </p>
        <p class="content-box__text-item">
          <span class="content-box__span"> Фильтры.</span> Нужно&nbsp;ли менять
          фильтры в&nbsp;кондиционере и&nbsp;как часто? Во&nbsp;внутреннем блоке
          кондиционера установлены сетки, которые фильтруют воздух
          от&nbsp;крупной пыли. Эти сетки может мыть сам пользователь.
          Открывается панель (а&nbsp;в&nbsp;некоторых конструкциях и&nbsp;этого
          делать не&nbsp;надо), сетка снимается и&nbsp;промывается водой или
          протирается тряпкой. Внутри кондиционера находится теплообменник,
          на&nbsp;нем образуется конденсат. А&nbsp;плесень и&nbsp;другие
          микроорганизмы растут там, где высокая влажность. Так что
          на&nbsp;теплообменнике внутреннего блока нарастают микроорганизмы.
          И&nbsp;чтобы их&nbsp;удалить, нужно время от&nbsp;времени&nbsp;&mdash;
          желательно один раз в&nbsp;год перед сезоном&nbsp;&mdash; проводить
          чистку внутреннего блока. Этим занимается профессиональная монтажная
          организация. И&nbsp;в&nbsp;целом необходимо проводить диагностику.
          Например, проверять давление хладагента в&nbsp;системе так&nbsp;же раз
          в&nbsp;год.
        </p>
        <p class="content-box__text-item">
          <span class="content-box__span">
            Инверторный кондиционер - его преимущества.</span
          >Низкий уровень шума. Достигается за&nbsp;счет того, что большую часть
          времени кондиционер работает на&nbsp;пониженной мощности. Точное
          поддержание температуры. В&nbsp;помещении не&nbsp;происходит скачков
          температуры за&nbsp;счет постоянно включенного компрессора. Тяжело
          простудиться. За&nbsp;счет того, что компрессор большую часть времени
          работает на&nbsp;пониженной мощности&nbsp;&mdash; меньшая вероятность
          заболеть от&nbsp;холодного сквозняка. Экономия электроэнергии
          до&nbsp;30%. Достигается за&nbsp;счет отсутствия постоянного
          включения/выключения и&nbsp;плавной регулировки мощности. Быстры выход
          на&nbsp;нужную температуру. Связано с&nbsp;тем, что при включении
          кондиционер работает на&nbsp;повышенной мощности. Увеличенная
          &laquo;продолжительность жизни&raquo;. За&nbsp;счет пониженного износа
          компрессора с&nbsp;отсутствием постоянных пусков. Работа при низких
          температурах на&nbsp;обогрев. За&nbsp;счет того, что компрессор
          постоянно работает и&nbsp;остается теплым.
        </p>
        <p class="content-box__text-item">
          <span class="content-box__span"> Остались вопросы? Звоните нам!</span>
        </p>
      </div>
    </div>
  </div>

  <FooterComponent />
</template>

<script>
import FooterComponent from "../components/FooterComponent.vue";

export default {
  components: {
    FooterComponent,
  },
  setup() {
    return {};
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
